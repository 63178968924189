<template>
  <div>
    <a-modal :visible="loadVisible" :title="title" width="40%" @cancel="cancel" @ok="submit" :confirmLoading="btnLoading">
      <div class="m-b2">
        <div class="title"><span>*</span>选择路线</div>
        <a-cascader v-model:value="form.startAddress" :change-on-select="true" :options="$store.state.app.cityDict" :show-search="{ filter }" placeholder="请选择" />
        <span class="m-l2"></span>
        <a-cascader v-model:value="form.endAddress" :change-on-select="true" :options="$store.state.app.cityDict" :show-search="{ filter }" placeholder="请选择" />
        <a-button class="m-l1" @click="syncAdd" type="primary">同步小车线路</a-button>
      </div>
      <div class="m-b2" v-if="toolType === 1">
        <div class="title"><span>*</span>选择司机</div>
        <div class="tabBox">
          <div>
            <a-input style="width:23%" v-model:value="name" placeholder="请输入" />
            <a-button class="m-l1" @click="search" type="primary">搜索</a-button>
          </div>
          <div class="m-b1"></div>
          <ly-table :columns="columns" size="small" :data="data" :pagination="pagination" @pageChange="pageChange" :loading="loading">
            <template #add="{ record }">
              <a v-if="record.id === celID">已选择</a>
              <div v-else class="flex ju-center">
                <div class="nameBox" @click="celDriver(record)"><a>选择该司机</a></div>
              </div>
            </template>
          </ly-table>
        </div>
      </div>
      <div>
        <div class="title"><span>*</span>填写单台运费</div>
        <a-input-number style="width:33%" v-model:value="form.freight" suffix="￥" placeholder="请填写" />
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { driverPage } from '@/api/crmManagement/crm'
import { transportAssembled } from '@/api/transport/vehicle' 
import { message } from 'ant-design-vue';
const props = defineProps({
  loadVisible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  selectedID:{
    type: Object,
    default: {}
  },
  toolType:{
    type: Number,
    default: 1
  },
  carrierDriverId:{
    type: String,
    default: ''
  },
  selectedVehicles:{
    type: Array,
    default: [],
  }
})
const emit = defineEmits(['update:loadVisible','loadSuccess'])
const form = ref({
  startAddress: null,
  endAddress: null,
  freight: null
})
const btnLoading = ref(false)
const name = ref(null)
const data = ref([])
const celID = ref('')
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
const cancel = () => {
  emit('update:loadVisible', false)
}
const pageChange = (e) => {
  pagination.value = e
  reqDriverPage()
}
//提交
const submit = () => {
  if(!form.value.startAddress || form.value.startAddress.length === 0){
    message.warning('请选择起运地')
    return
  }else if(form.value.startAddress.length === 1) {
   message.warning('起运地必须选择到市')
   return
  }
  if(!form.value.endAddress || form.value.endAddress.length === 0){
    message.warning('请选择目的地')
    return
  }else if(form.value.endAddress.length === 1) {
   message.warning('目的地必须选择到市')
   return
  }
  let carrierDriverId = props.toolType === 1 ?  celID.value : props.selectedID.carrierDriverId
  if(!carrierDriverId){
    message.warning('请选择司机')
    return
  }
  if(form.value.freight === null){
    message.warning('请填写单台运费')
    return
  }
  
  let startAddress = {
    province:form.value.startAddress[0],
    city:form.value.startAddress[1],
    area: form.value.startAddress.length === 3 ? form.value.startAddress[2] :''
  }
  let endAddress = {
    province:form.value.endAddress[0],
    city:form.value.endAddress[1],
    area:form.value.endAddress.length === 3 ? form.value.endAddress[2] :''
  }
  btnLoading.value = true
  transportAssembled({
    carrierDriverId: carrierDriverId,
    carrierId:props.selectedID.carrierId,
    endAddress:endAddress,
    startAddress:startAddress,
    freight:form.value.freight,
    orderVehicleIds:props.selectedID.orderVehicleIds,
    carrierTruckId:props.selectedID.carrierTruckId
  }).then( res => {
    if(res.code !== 10000) return
    message.success(res.msg)
    emit('loadSuccess')
    cancel()
  }).finally(() => {
    loading.value = false
  })
}
//搜索
const search = () => {
  pagination.value.current = 1
  reqDriverPage()
}
//选择司机
const celDriver = (record) => {
  celID.value = record.id
}

const columns = ref([
  {
    title: '司机姓名',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '司机电话',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    width: '20%',
    slots: {
      customRender: 'add'
    }
  },
])
const reqDriverPage = () => {
  driverPage({
    customerId: props.selectedID.carrierId,
    name:name.value,
    current:pagination.value.current,
    size:pagination.value.pageSize
  }).then(res => {
    if (res.code !== 10000) return
    console.log(res);
    data.value = res.data.records
    pagination.value.total = res.data.total
  })
}
const syncAdd = () => {
  if(props.selectedVehicles&& props.selectedVehicles.length > 0){
      let list = props.selectedVehicles[0]
      form.value.startAddress = [String(list.startProvince),String(list.startCity),String(list.startArea)]
      form.value.endAddress = [String(list.endProvince),String(list.endCity),String(list.endArea)]
  }
}
onMounted(() => {
  reqDriverPage()
})
</script>

<style lang="less" scoped>
.title {
  margin-bottom: 5px;
  span {
    color: red;
  }
}

.tabBox {
  border: 1px solid rgba(238, 238, 238, 1);
  width: 100%;
  padding: 8px;
}

.nameBox {
  padding: 4px;
  width: 80px;
  font-size: 12px;
  background: rgba(242, 242, 242, 1);
}
</style>